
export const environment = {
  production: 'false',
  BUILD_ENV: 'test',
  API_URL: 'https://testapi.safetk.app/',
  APP_INSIGHTS_KEY: 'NOT USED CURRENTLY',
  AZURE_STORAGE_HOSTNAME: '',
  AZURE_CONTAINER_NAME: '',
  SIGNALR_URL: 'https://testapi.safetk.app/signalr'
};
